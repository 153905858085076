<!-- component -->
<!-- This is an example component -->





<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="isOpen" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
  
      <!--
        Modal panel, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div class="inline-block align-bottom bg-white rounded-md px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:max-w-4xl  w-full sm:p-6">
        <div class=" sm:block absolute top-0 right-0 pt-4 pr-4">
          <button (click)="isOpen = !isOpen" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <!--  hier kommt der Code-->
        <div class="">
            <div>
                <form [formGroup]="questionsForm">
                <div class="max-w-3xl mx-auto px-4 py-10">
                    
                    <div *ngIf="completed && unsuccessful_test">
                        <div class="bg-white rounded-md p-10 flex items-center justify-between">
                            <div>
                                <svg class="mb-4 h-20 w-20 text-red-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                                </svg>
                                <h2 class="text-2xl mb-4 text-red-800 text-center font-bold">Sie haben {{ correctAnswers }} / {{ questions.length }} ({{ parseInt((correctAnswers / questions.length * 100)) }} %) Fragen richtig beantwortet.</h2>
                                <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">Das hat leider nicht gereicht um den Wissenstest zu bestehen :(</h2>
        
                                <div class="text-gray-600 mb-8">
                                    Um diese Unterweisung zu bestehen, müssen Sie mindestens 80 % der Fragen  richtig beantwortet haben. Bitte wiederholen Sie die Unterweisung und den Wissenstest noch einmal.
                                </div>
                            </div>
                            
                        </div>
                        <div class="flex items-center bg-white ">
                            <div class="w-full text-right">
                                <button (click)="isOpen = !isOpen"
                                    class="py-2 mx-4 px-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" 
                                >Schließen</button>
        
                                <button type="submit" (click)="restartTest($event)"
                                    class="py-2 px-4 mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
                                >nochmals probieren</button>
                            </div>

                        </div>
                    </div>
                   <!-- <div>  -->
                    <div *ngIf="completed && success_test">
                        <div class="bg-white rounded-md p-10 flex items-center justify-between">
                            <div>
                                <svg class="mb-4 h-20 w-20 text-green-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>
        
                                <h2 class="text-2xl mb-4 text-green-800 text-center font-bold">Herzlichen Glückwunsch! Sie haben den Wissenstest erfolgreich bestanden :)</h2>
                                <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold"> Sie haben {{ correctAnswers }} / {{ questions.length }} ({{ parseInt((correctAnswers / questions.length * 100)) }} %) Fragen richtig beantwortet!</h2>
        
                                <div class="text-gray-800 mb-8">
                                    Ihre Prüfungsergebnisse wurden erfolgreich an die ITC Graf GmbH zur weiteren Bearbeitung weitergeleitet. Sie erhalten in Kürze Ihr Zerkifikat für Ihre bestandene Unterweisung.
                                </div>

                                <div class="">
                                    <div class="text-gray-800">
                                        Sehen Sie sich hier Ihre Ergebnisse des Wissenstests an:
                                    </div>
                                    <div *ngFor="let question of questions; let i = index">
                                        <fieldset class="mt-8">
                                            <legend class="text-lg font-bold text-gray-700 leading-tight">{{ question.question }}</legend>
                                            
                                            <div class="mt-4 space-y-4">
                                              <div class="flex items-center" *ngFor="let answer of question.possible_answers">
                                                <input  value="{{ answer.id }}" class="focus:ring-indigo-500 h-4 w-4 text-red-600 border-gray-300"  [ngClass]="{'text-green-700' : answer.id === question.correct_answer, 'text-red-800' : answer.id === parseInt(questionsForm.controls[question._id].value)}"  type="radio" checked>
                                                <label for="push_everything" class="ml-3">
                                                  <span class="block text-sm font-medium font-bold text-gray-700" [ngClass]="{'text-green-700' : answer.id === question.correct_answer, 'text-red-800' : answer.id === parseInt(questionsForm.controls[question._id].value)}" >{{ answer.answer }} </span>
                                                </label>
                                              </div>
                                            </div>
                                          </fieldset>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <div class="flex items-center bg-white ">
                            <div class="w-full text-right">
                                <button (click)="isOpen = !isOpen"
                                    class="py-2 mx-4 px-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" 
                                >Schließen</button>
                            </div>

                        </div>
                    </div>


                    <div *ngIf="server_error">
                        <div class="bg-white rounded-md p-10 flex items-center justify-between">
                            <div>
                                <svg class="mb-4 h-20 w-20 text-red-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                                </svg>
                                <h2 class="text-2xl mb-4 text-red-800 text-center font-bold">Leider ist etwas schief gelaufen.</h2>
                                <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">Das Problem liegt nicht an Ihnen.</h2>
        
                                <div class="text-gray-600 mb-8">
                                   Bitte loggen Sie sich nochmals aus Ihrem Account aus und versuchen Sie den Test nochmals. Sollten weiterhin Probleme auftreten, informieren Sie uns bitte.
                                </div>
                            </div>
                            
                        </div>
                        <div class="flex items-center bg-white ">
                            <div class="w-full text-right">
                                <button (click)="isOpen = !isOpen"
                                    class="py-2 mx-4 px-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" 
                                >Schließen</button>
                            </div>
        

                        </div>
                    </div>
        
        
                   
        
                    <div *ngIf="!completed">	
                        <!-- Top Navigation -->
                        <div class="border-b-2 py-4">
                            <div class="uppercase tracking-wide text-xs font-bold text-gray-500 mb-1 leading-tight" x-text="`Step: ${step} of 3`"></div>
                            <div class="flex flex-col md:flex-row md:items-center md:justify-between">
                                <div class="flex-1">
                                    <div *ngIf="step === 0">
                                        <div class="text-lg font-bold text-gray-700 leading-tight">Persönliche Daten</div>
                                    </div>
                                    
                                    <div *ngIf="step >= 1">
                                        <div class="text-lg font-bold text-gray-700 leading-tight">Wissenstest</div>
                                    </div>
        
                                </div>
        
                                <div *ngIf="!completed" class="flex items-center md:w-64">
                                    <div class="w-full bg-white rounded-full mr-2">
                                        <div class="rounded-full bg-green-500 text-xs leading-none h-2 text-center text-white" [ngStyle]="{'width': parseInt(step / (questions.length + 1) * 100) + '%'}"></div>
                                    </div>
                                    <div class="text-xs w-10 text-gray-600">{{ parseInt(step / (questions.length + 1) *100) }} %</div>
                                </div>
                            </div>
                        </div>
                        <!-- /Top Navigation -->
        
                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div *ngIf="invalidForm" class="rounded-md bg-yellow-50 p-4 mt-4">
                            <div class="flex">
                            <div class="flex-shrink-0">
                                <!-- Heroicon name: solid/exclamation -->
                                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-yellow-800">
                                Warnung
                                </h3>
                                <div class="mt-2 text-sm text-yellow-700">
                                <p>
                                    Alle Felder müssen ausgefüllt sein. Bitte gehen Sie diesen Test nochmals durch. 
                                </p>
                                </div>
                            </div>
                            </div>
                        </div>
                        
                        
                        <!-- Step Content -->
                        <div class="py-10">
                            <div *ngIf="step === 0">
                                <div class="mb-5">
                                    <label for="firstname" class="font-bold mb-1 text-gray-700 block">Vorname</label>
                                    <input type="text" formControlName="firstName"
                                        class="w-full px-4 py-3md shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="Max">
                                </div>
        
                                <div class="mb-5">
                                    <label for="email" class="font-bold mb-1 text-gray-700 block">Nachname</label>
                                    <input type="text" formControlName="lastName"
                                        class="w-full px-4 py-3 rounded-md shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="Mustermann">
                                </div>
                                <div class="mb-5">
                                    <label for="email" class="font-bold mb-1 text-gray-700 block">Geburtstag (Format: tt.mm.jjjj)</label>
                                    <input type="text" formControlName="birthdate"
                                        class="w-full px-4 py-3 rounded-md shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="01.01.2001">
                                </div>
                                <div class="mb-5">
                                    <label for="email" class="font-bold mb-1 text-gray-700 block">Email</label>
                                    <input type="email" formControlName="email"
                                        class="w-full px-4 py-3 rounded-md shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="max.mustermann@musterdomain.de">
                                </div>
        
                            </div>
                            <div *ngFor="let question of questions; let i = index">
                                {{ appendFormField(question._id) }}
                                
                                <div *ngIf="step === i + 1">
        
                                    <fieldset class="mt-6">
                                        <legend class="text-lg font-bold text-gray-700 leading-tight">{{ question.question }}</legend>
                                        
                                        <div class="mt-4 space-y-4">
                                          <div class="flex items-center" *ngFor="let answer of question.possible_answers">
                                            <input formControlName="{{ question._id }}" name="{{ question._id }}" value="{{ answer.id }}" name="push_notifications" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
                                            <label for="push_everything" class="ml-3">
                                              <span class="block text-sm font-medium text-gray-700">{{ answer.answer }} </span>
                                            </label>
                                          </div>
                                        </div>
                                      </fieldset>          
                                </div>

                            </div>
        
                        </div>
                        
                        <!-- / Step Content -->
                    </div>
                </div>
        
                <!-- Bottom Navigation -->	
                <div *ngIf="!completed" class="relative bottom-0 left-0 right-0 py-5 bg-white">
                    <div class="max-w-3xl mx-auto px-4">
                        <div *ngIf="step === questions.length" class="mb-4 flex items-center">
                            <input id="abgs" name="agbs" formControlName="agbs" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                            <label for="agbs" class="ml-2 block text-sm text-gray-900">
                              Die von Ihnen eingegebenen personenbezogenen Daten werden an die ITC Graf GmbH weitergeleitet und zum Zwecke der Auftragserfüllung weiterverarbeitet. Unsere allgemeine Datenschutzerklärung finden Sie <a href="https://www.itc-graf.de/datenschutz">hier</a>.
                            </label>
                          </div>  
                        <div class="flex justify-between">

                            <div class="w-1/2">
                                <button
                                    *ngIf="step > 0"
                                    (click)="step = step - 1"
                                    class="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" 
                                >Zurück</button>
                            </div>
        
                            <div class="w-1/2 text-right">
                                <button
                                    *ngIf="step < questions.length"
                                    (click)="step = step + 1"
                                    class="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
                                >Weiter</button>
        
                                <button type="submit"
                                    (click)="checkResults($event)"
                                    *ngIf="step === questions.length"
                                    class="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" 
                                >Absenden</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
                <!-- / Bottom Navigation https://placehold.co/300x300/e2e8f0/cccccc -->	
            </div>

      </div>
    </div>
  </div>
  

  <!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="openAGB" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
  
      <!--
        Modal panel, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button (click)="openAGB = false" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <!-- Heroicon name: outline/exclamation -->
            <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Deactivate account
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Are you sure you want to deactivate your account? All of your data will be permanently removed from our servers forever. This action cannot be undone.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button (click)="openAGB = false" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
            Deactivate
          </button>
        </div>
      </div>
    </div>
  </div>
  
  