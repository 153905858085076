import { ConfirmationService } from './../../_services/confirmation.service';
import { User, training } from './../../_models/user';
import { LocalStorageService } from './../../_services/local-storage.service';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { possible_answers, question } from 'src/app/_models/user';
import { ConfirmationData } from 'src/app/_models/confirmation-data';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.scss']
})


export class MultiStepFormComponent implements OnInit {
  user: User;
  @Input() questions: question[];
  @Input() trainingName: string;

  isOpen: boolean = false;
  @Input() openEvent: Observable<void>;
  private eventSubscription: Subscription;

  parseInt: any;
  completed: boolean = false;
  invalidForm: boolean = false;
  step: number = 0;

  questionsForm: FormGroup;
  correctAnswers: number = 0;

  success_test: boolean = false;
  unsuccessful_test: boolean = false;
  server_error: boolean = false;

  openAGB: boolean = false;




  constructor(
    private formBuilder: FormBuilder,
    private localstorageService: LocalStorageService,
    private confirmationService: ConfirmationService
  ) {
    this.parseInt = parseInt;

    //create form
    this.questionsForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthdate: ['', Validators.required],
      email: [''],
      agbs: [false, Validators.requiredTrue]
    });

    this.user = this.localstorageService.getCurrentUser();
   }

  ngOnInit(): void {

    this.questions.forEach(question => {
      question.possible_answers = this.shuffle(question.possible_answers)
    });


    this.eventSubscription = this.openEvent.subscribe(() => {
      this.isOpen = !this.isOpen;
    });
  }

  public appendFormField (name: string) {
    this.questionsForm.addControl(name,  new FormControl('', Validators.required));
  }

public restartTest (event?: Event) {
  this.step = 0;
  this.completed = false;

  this.unsuccessful_test = false;
  this.success_test = false;
  this.invalidForm = false;
  this.server_error = false;

  this.correctAnswers = 0;

  this.questionsForm.reset();
}


  public shuffle(array: possible_answers[]) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  public checkResults (event?: Event) {
    console.log('checking results');
    if (this.questionsForm.invalid) {
      this.invalidForm = true;
      return
    }
    this.completed = true

    //checking answers
    this.questions.forEach(question => {
      if (question.correct_answer === parseInt(this.questionsForm.controls[`${question._id}`].value)) {
        this.correctAnswers += 1;
      }
    });
    this.step += 1;

    var score = this.correctAnswers / this.questions.length;
    if (score  >= 0.8) {

      //create object
      var confirmationObject: ConfirmationData = {
        employee: {
          firstName: this.questionsForm.controls.firstName.value,
          lastName: this.questionsForm.controls.lastName.value,
          birthdate: this.questionsForm.controls.birthdate.value,
          email: this.questionsForm.controls.email.value,
      },
      training: {
          name: this.trainingName,
          score: `${score * 100}%`
      }
      }

      this.confirmationService.sendConformation(confirmationObject)
      .pipe(first())
      .subscribe(
        data => {
          this.success_test = true;
          console.log('confirming');
        },
        error => {
          console.error(error);
          this.server_error = true;
        }
      )
  
      
      //Request ans Backend um die Email zu versenden. 
    } else {
      this.unsuccessful_test = true;
      console.log('unsuccessful');
    }

    
  }
}
