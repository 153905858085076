import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { training, trainingAddUser } from 'src/app/_models/user';
import { TrainingsService } from 'src/app/_services/trainings.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-add-user-page',
  templateUrl: './add-user-page.component.html',
  styleUrls: ['./add-user-page.component.scss']
})
export class AddUserPageComponent implements OnInit {

  trainings: trainingAddUser[] = [];

  loading = false;
  submitted = false;
  message: string = '';

  addUserForm: FormGroup;

  constructor(
    private trainingService: TrainingsService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { 
    this.addUserForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      street: ['', Validators.required],
      postcode: ['', Validators.required],
      city: ['', Validators.required],
      company: ['', Validators.required],
      security: ['', Validators.required],
      trainings: [[], ]
    })
  }

  ngOnInit(): void {
    this.getTrainings();
  }

  public getTrainings() {
    this.trainings = [];
    this.trainingService.getTrainings()
      .pipe(first())
      .subscribe(
        data => {
         data.forEach(element => {
           this.trainings.push(element);
         });
        }
      )
  }

  public onCheckChange(event) {

    if(event.target.checked){
      console.log('push')
      this.addUserForm.controls.trainings.value.push(event.target.value);
    }
    else{
      let i: number = 0;
      this.addUserForm.controls.trainings.value.forEach((ctrl: string) => {
        if(ctrl == event.target.value) {

           var training = this.addUserForm.controls.trainings.value;
           training.splice(i, 1);
          return;
        }
        i++;
      });
    }
  }

  public onSubmit(event?: Event) {

    if (this.addUserForm.invalid) return 
    if (this.addUserForm.controls.security.value != 'ITC2021') return

    var userObject: any = {
      email: this.addUserForm.controls.email.value,
      password: this.addUserForm.controls.password.value,
      street: this.addUserForm.controls.street.value,
      postcode: this.addUserForm.controls.postcode.value,
      city: this.addUserForm.controls.city.value,
      company: this.addUserForm.controls.company.value,
      trainings: this.addUserForm.controls.trainings.value
    }
    console.log(this.addUserForm);
    this.userService.addUser(userObject)
      .pipe(first())
      .subscribe(
        () => {
          console.log('success');
          this.message = 'Benutzer wurde erfolgreich angelegt';
        },
        error => {
          console.log(error);
          this.message = 'Error';
        }
      )
  }

}
