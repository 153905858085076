import { environment } from './../../environments/environment';
import { User } from './../_models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface refreshtoken {
    accesstoken: string,
    refreshtoken: string
}


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private hostUrl = environment.hostUrl;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string): any {
        const headers = new HttpHeaders({'Content-Type':'application/json'});

        return this.http.post<any>(`${this.hostUrl}/authenticate`, { email: email, password: password })
            .pipe(map((user: User) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout(): void {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        //this.currentUserSubject.next(null);
    }

    refreshtoken(): Observable<refreshtoken> {
        const user: User = JSON.parse(localStorage.getItem('currentUser')!);
        const currentRefreshtoken = user.refreshtoken;
        console.log('making request to refreshtoken');

        return this.http.post<refreshtoken>(`${this.hostUrl}/authenticate/refreshtoken`, { refreshtoken: currentRefreshtoken})
                    .pipe(tap((response: refreshtoken) => {
                        console.log(user.accesstoken);
                        user.accesstoken = response.accesstoken;
                        console.log(user.accesstoken);
                        user.refreshtoken = response.refreshtoken;
                        localStorage.setItem('currentUser', JSON.stringify(user));

                    }))
    }
}
