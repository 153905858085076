<!-- This example requires Tailwind CSS v2.0+ -->
<div class="h-screen flex overflow-hidden bg-gray-100">
    <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
      <button (click)="isExpandedMobile = !isExpandedMobile"  class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span class="sr-only">Open sidebar</span>
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>  
      
      <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
      <div class="md:hidden">
        
        <div *ngIf="isExpandedMobile" class="fixed inset-0 flex z-40">
          <!--
            Off-canvas menu overlay, show/hide based on off-canvas menu state.
    
            Entering: "transition-opacity ease-linear duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "transition-opacity ease-linear duration-300"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <div class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
          <!--
            Off-canvas menu, show/hide based on off-canvas menu state.
    
            Entering: "transition ease-in-out duration-300 transform"
              From: "-translate-x-full"
              To: "translate-x-0"
            Leaving: "transition ease-in-out duration-300 transform"
              From: "translate-x-0"
              To: "-translate-x-full"
          -->
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button (click)="isExpandedMobile = !isExpandedMobile" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span class="sr-only">Close sidebar</span>
                <!-- Heroicon name: x -->
                <svg class="h-6 w-6 text-white" xmlns="/assets/images/logo.svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img class="h-8 w-auto" src="/assets/images/logo.svg" alt="Workflow">
              </div>
              <nav class="mt-5 px-2 space-y-1">
                <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                <a routerLink="/dashboard" routerLinkActive="bg-gray-900" class="hover:bg-gray-700 hover:text-white text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <!-- Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300" -->
                  <!-- Heroicon name: home -->
                  <svg class="text-gray-300 mr-4 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  Dashboard
                </a>
  
  
  
      
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" class="flex-shrink-0 group block">
                <div class="flex items-center">
                  <div>
                    <span class="font-medium leading-none text-white">{{ user.company.substring(0,2) }}</span>
                   </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-white">
                    {{ user.company }}
                    </p>
                    <p class="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                      {{ user.email }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="flex-shrink-0 w-14">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </div>
    
      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:flex-shrink-0">
        <div class="flex flex-col w-64">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="flex flex-col h-0 flex-1 bg-gray-800">
            <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div class="flex items-center flex-shrink-0 px-4">
                <img class="h-8 w-auto" src="/assets/images/logo.svg" alt="Workflow">
              </div>
                      <!-- Sidebar Search -->
          <!-- <div class="px-3 mt-5">
            <label for="search" class="sr-only">Search</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                
                <svg class="mr-3 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input type="text" name="search" id="search" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm border-gray-300 rounded-md" placeholder="Search">
            </div>
          </div> -->
              <nav class="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
                <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                <a routerLink="/dashboard" routerLinkActive="bg-gray-900" class="hover:bg-gray-700 hover:text-white text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <!-- Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300" -->
                  <!-- Heroicon name: home -->
                  <svg class="text-gray-300 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  Dashboard
                </a>           
    
              </nav>
            </div>
    
            <button type="submit" (click)="logout($event)"
                class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none">
                Ausloggen
            </button>
           
    
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <a routerLink="/dashboard" class="flex-shrink-0 w-full group block">
                <div class="flex items-center">
                  <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                      <span class="font-medium leading-none text-white">{{ user.company.substring(0,2) }}</span>
                    </span>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-white">
                    {{ user.company }}
                    </p>
                    <p class="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                      {{ user.email }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    
  
  
    