import { training, User } from './../../_models/user';
import { LocalStorageService } from './../../_services/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-schulung',
  templateUrl: './schulung.component.html',
  styleUrls: ['./schulung.component.scss']
})
export class SchulungComponent implements OnInit {

  eventsSubject: Subject<void> =  new Subject<void>();
  showTestButton: boolean = false;

  user: User;
  trainingId: string;
  training: training;


  warning_modal: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private localstorageService: LocalStorageService
  ) {
    this.user = this.localstorageService.getCurrentUser();
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.trainingId = params['id'];
      console.log(this.trainingId);
      this.training = this.user.trainings.find(training => training.training._id === this.trainingId);
      console.log(this.training);
    });
  }

  public emitOpen() {
    this.eventsSubject.next();
  } 

}
