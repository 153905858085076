import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private hostUrl = environment.hostUrl;

  constructor(
    private http: HttpClient
  ) { }

  public addUser(userObject: object) {
    return this.http.post(`${this.hostUrl}/adduser`, userObject)
      .pipe(map((response: any) => {
        return response
      }));
  }
}
