import { TokenInterceptor } from './_services/token-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './_pages/login/login.component';
import { DashboardComponent } from './_pages/dashboard/dashboard.component';
import { LoginFormComponent } from './_components/login-form/login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { SchulungComponent } from './_pages/schulung/schulung.component';
import { MultiStepFormComponent } from './_components/multi-step-form/multi-step-form.component';
import { FooterComponent } from './_components/footer/footer.component';
import { AddUserPageComponent } from './_pages/add-user-page/add-user-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    LoginFormComponent,
    NavbarComponent,
    SchulungComponent,
    MultiStepFormComponent,
    FooterComponent,
    AddUserPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
