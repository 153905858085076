<!-- This example requires Tailwind CSS v2.0+ -->
<footer class="bg-white  w-full bottom-0">
    <div class="max-w-7xl mx-auto py-12 px-4  overflow-hidden sm:px-6 lg:px-8">
      <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
        <div class="px-5 py-2">
          <a href="https://itc-graf.de/" target="_blank" class="text-base text-gray-500 hover:text-gray-900">
            ITC Graf GmbH
          </a>
        </div>
  
        <div class="px-5 py-2">
          <a href="https://itc-graf.de/impressum" target="_blank" class="text-base text-gray-500 hover:text-gray-900">
            Impressum
          </a>
        </div>
  
        <div class="px-5 py-2">
          <a href="https://itc-graf.de/datenschutz" target="_blank" class="text-base text-gray-500 hover:text-gray-900">
            Datenschutzerklärung
          </a>
        </div>
  
      </nav>
      <p class="mt-8 text-center text-base text-gray-400">
        &copy; 2021 ITC Graf GmbH, All rights reserved.
      </p>
    </div>
  </footer>
  