import { map } from 'rxjs/operators';
import { User } from './../_models/user';

import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { ConfirmationData } from '../_models/confirmation-data';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  
  private hostUrl = environment.hostUrl;


  constructor(
    private http: HttpClient,
    private localstorageService: LocalStorageService
  ) { }

  public getHeader(accesstoken: string) {
    const headers = ({
      'Authorization': `Bearer ${accesstoken}`
    });
    return headers
  }

  public sendConformation(data: ConfirmationData): any {
    const user: User = this.localstorageService.getCurrentUser();
    const header = this.getHeader(user.accesstoken);

    return this.http.post(`${this.hostUrl}/sendemail`, data, {headers: header})
      .pipe(map((response: any) => {
        return response
      }));
  }



}
