<div class="h-screen flex overflow-hidden bg-gray-100">
  <app-navbar></app-navbar>
  <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <!-- <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button  class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span class="sr-only">Open sidebar</span>
          
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div> -->
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
        <div class="">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
            



            <!-- This example requires Tailwind CSS v2.0+ -->
              <div class="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                <div class="relative max-w-7xl">
                  <div class="">
                    <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                      Verfügbare Schulungen
                    </h2>
                    <p class="mt-3 max-w-2xl  text-xl text-gray-500 sm:mt-4">
                      Wichtige Schulungen für Ihren Arbeitsschutz
                    </p>
                  </div>
                  <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    <div *ngFor="let training of user.trainings">
                      
                        <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                          <div class="flex-shrink-0">
                            <img class="h-48 w-full object-cover" src="/assets/images/{{ training.training.picture_path }}" alt="">
                          </div>
                          <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div class="flex-1">
                              <p class="text-sm font-medium text-indigo-600">
                                <a href="#" class="hover:underline">
                                  Schulung
                                </a>
                              </p>
                            
                                <p class="text-xl font-semibold text-gray-900 mt-2">
                                 {{ training.training.name }}
                                </p>
                                <p class="mt-3 text-base text-gray-500">
                                  {{ training.training.description }}
                                </p>
                              
                            </div>
                            <div class="mt-6 flex items-center">
                              <a [routerLink]="['/schulung', training.training._id ]" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Schulung ansehen
                              </a>
                            </div>
                          </div>
                        </div>
                    </div>            
                  </div>
                </div>


              </div>


          </div>

        </div>
        <app-footer></app-footer>
      </main>
    </div>
</div>

