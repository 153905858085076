<div class="h-screen flex overflow-hidden bg-gray-100">
    <app-navbar></app-navbar>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <!-- <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button  class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open sidebar</span>
            
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div> -->
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
          <div class="">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
             

                <div class="mt-8 md:flex md:items-center md:justify-between">
                    <div class="flex-1 min-w-0">
                    <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                        {{ training.training.name }}
                    </h2>
                    </div>
                    <div *ngIf="showTestButton" class="mt-4 flex md:mt-0 md:ml-4">
                    <button (click)="emitOpen()" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Wissenstest
                    </button>
                    </div>
                </div>
                <div class="my-4 text-gray-800">
                  {{ training.training.description }}
                </div>

              <div class="py-4 my-8">
                <video oncontextmenu="return false;" width="100%" height="800" controls="false" controlsList="nodownload" (ended)="showTestButton = true">

                  <source src="/assets/videos/{{ training.training.video_path }}"  type="video/mp4" />
                </video>
              </div>

                <app-multi-step-form [openEvent]="eventsSubject.asObservable()" [questions]="training.training.questions" [trainingName]="training.training.name"></app-multi-step-form>
  
  
  
            </div>
  
          </div>
          <app-footer></app-footer>
        </main>
      </div>
  </div>



  <!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="warning_modal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <!-- Heroicon name: outline/exclamation -->
          <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            © 2021 ITC Graf GmbH          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Alle durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Alle Rechte an Texten, Abbildungen, Audio/Video-Dateien und sämtlichen weiteren veröffentlichten Informationen behält sich die ITC Graf GmbH vor. Eine Reproduktion, Verarbeitung, Vervielfältigung, Verbreitung sowie Speicherung in Datenverarbeitungsanlagen des Ganzen oder von Teilen, gleich welcher Art, bedarf einer vorherigen schriftlichen Zustimmung der ITC Graf GmbH. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Zuwiderhandlungen werden zivil- und strafrechtlich verfolgt.            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button (click)="warning_modal = !warning_modal" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
          Verstanden
        </button>
      </div>
    </div>
  </div>
</div>
