import { User } from './../../_models/user';
import { LocalStorageService } from './../../_services/local-storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  user: User;

  constructor(
    private localstorageService: LocalStorageService
  ) {
    this.user = this.localstorageService.getCurrentUser();
    console.log(this.user);
   }

  ngOnInit(): void {
  }

}
