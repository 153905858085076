<div *ngIf="message.status" class="rounded-md bg-red-50 p-4">
  <div class="flex">
    <div class="flex-shrink-0">
      <!-- Heroicon name: solid/x-circle -->
      <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
      </svg>
    </div>
    <div class="ml-3">
      <h3 class="text-sm font-medium text-red-800">
      Fehler
      </h3>
      <div class="mt-2 text-sm text-red-700">
        <p>{{ message.text }} </p>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)" class="space-y-6">
    <div>
      <label for="email" class="block text-sm font-medium text-gray-700">
        Email
      </label>
      <div class="mt-1">
        <input type="email" formControlName="email" [ngClass]="{ 'border-2 border-red-500': submitted && loginForm.controls.email.errors  }" name="email" type="email" autocomplete="email"  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        <div *ngIf="submitted && loginForm.controls.email.errors" class="invalid-feedback">
          <div *ngIf="loginForm.controls.email.errors.required">Email is required</div>
        </div>
      </div>
    </div>

    <div class="space-y-1">
      <label for="password" class="block text-sm font-medium text-gray-700">
        Passwort
      </label>
      <div class="mt-1">
        <input type="password"  formControlName="password" [ngClass]="{ 'border-2 border-red-500': submitted && loginForm.controls.email.errors  }" type="password" autocomplete="current-password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        <div *ngIf="submitted && loginForm.controls.password.errors" class="invalid-feedback">
          <div *ngIf="loginForm.controls.password.errors.required">Password is required</div>
        </div>
      </div>
    </div>


    <div>
      <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Anmelden
      </button>
    </div>
  </form>