import { LocalStorageService } from './../../_services/local-storage.service';
import { User } from './../../_models/user';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  user: User;
  isExpandedMobile: Boolean = false;

  constructor(
    private router: Router,
    private localstorageService: LocalStorageService,
    private authenticationService: AuthenticationService

  ) {
    this.user = this.localstorageService.getCurrentUser();
   }

  ngOnInit(): void {
  }

  public logout(event?: Event): void {
    this.authenticationService.logout();
    this.router.navigate(['']);
  }

}
