import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { training, trainingAddUser } from '../_models/user';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingsService {

  private hostUrl = environment.hostUrl

  constructor(
    private http: HttpClient,
    private localstorageService: LocalStorageService
  ) { }

    public getTrainings() {
      return this.http.get(`${this.hostUrl}/trainings`)
        .pipe(map((response: trainingAddUser[]) => {
          return response
        }));
    }

    

}
